import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import {
  AUTO_CREATE_VERSION_ON_EXPORT,
  CSV,
  CURRENT_VIEW,
  CURRENT_VIEW_EXPORT_TYPE,
  CURRENT_VIEW_VALUE,
  CURRENT_VIEW_SPLIT_EXPORT_TYPE,
  CURRENT_VIEW_REVIT_EXPORT_TYPE,
  ELECTRO_VALUE,
  JOURNEY_WORLD,
  MCW_VALUE,
  PDF,
  REVIT_EXPORT,
  SPECIFICATION_SHEET,
  XLSX,
} from '@/constants/exportScheduleItems';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';

import ProjectsApi from '@/services/graphql/projects';

export default {
  props: {
    isSharedLink: {
      type: Boolean,
      default: false,
    },
    isVisiblePrint: {
      type: Boolean,
      default: true,
    },
    tableType: {
      type: String,
      default: 'schedule',
    },
  },
  data: () => ({
    customLimitsByView: {
    },
    dialogForLimitByTag: false,
    exportingType: null,
    fileFormat: null,
    isShowLimitProducts: true,
    listKey: 0,
    templateName: null,
  }),
  computed: {
    ...mapGetters({
      currentVersion: 'ProjectVersions/getCurrentVersion',
      canRevitExport: 'UserRoles/canRevitExport',
    }),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleCompare', ['compareVersion', 'compareColumns']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    currentViewExport() {
      return {
        name: {
          value: CURRENT_VIEW_VALUE,
          title: CURRENT_VIEW,
        },
        canUseAppropriateRole: this.canUseExportToForCollaboratorRoleComputed,
        template: '',
        types: [
          {
            name: XLSX,
            desc: 'Separate fields',
            icon: 'mdi-file-excel',
            value: CURRENT_VIEW_SPLIT_EXPORT_TYPE,
          },
          {
            name: PDF,
            desc: 'Separate fields',
            icon: 'mdi-file-pdf-box',
            value: CURRENT_VIEW_SPLIT_EXPORT_TYPE,
          },
          {
            name: XLSX,
            desc: 'Similar fields merged',
            icon: 'mdi-file-excel',
            value: CURRENT_VIEW_EXPORT_TYPE,
          },
          {
            name: PDF,
            desc: 'Similar fields merged',
            icon: 'mdi-file-pdf-box',
            value: CURRENT_VIEW_EXPORT_TYPE,
          },
        ],
      };
    },
    revitObj() {
      return {
        name: CSV,
        disabled: !this.canRevitExport.allowed,
        label: REVIT_EXPORT,
        showTooltip: !this.canRevitExport.allowed,
        tooltipText: 'Please Upgrade Workspace',
        value: CURRENT_VIEW_REVIT_EXPORT_TYPE,
      };
    },
  },
  methods: {
    ...mapActions({
      exportScheduleProject: 'ExportSchedule/exportScheduleProject',
      handleError: 'handleError',
    }),
    ...mapMutations({
      spinner: 'spinner',
      setIssuanceMenu: 'ProjectVersions/setIssuanceMenu',
    }),
    async exportProject({ viewId, skipVersion } = {
      viewId: null,
      skipVersion: true,  // revert this after fixing create version issue after export
    }) {
      const { fileFormat, templateName, exportingType } = this;
      const {
        scheduleId: tableId,
        tableType,
        detailProjectData,
        compareVersion,
        currentVersion,
        compareColumns: columns,
      } = this;
      const { id } = detailProjectData;
      try {
        this.spinner(true);
        const { query } = this.$route;
        const versionId = query?.version;
        const objectForCompareVersions = columns.length
          ? {
            primaryVersionId:
                currentVersion === DEFAULT_PROJECT_VERSION ||
                compareVersion === DEFAULT_PROJECT_VERSION
                  ? undefined
                  : currentVersion,
            secondaryVersionId:
                compareVersion === DEFAULT_PROJECT_VERSION ? currentVersion : compareVersion,
            columns,
          }
          : undefined;
        const workspaceId = this.activeWorkspaceId;

        // Important note when changing exportType here:
        // - Some file format exports rrely on it being empty ("") to work.
        const variables = {
          tableType,
          tableId,
          templateName,
          projectId: id,
          viewId,
          fileFormat,
          versionId,
          workspaceId,
          sharedLink: this.isSharedLink,
          ...(exportingType == SPECIFICATION_SHEET && {
            columnName: SPECIFICATION_SHEET,
          }),
          ...objectForCompareVersions,
          ...(exportingType === ELECTRO_VALUE && {
            exportType: 'electrolightXlsx',
          }),
          ...(exportingType === MCW_VALUE && {
            exportType: 'mcwScheduleXlsx',
          }),
          ...(exportingType === JOURNEY_WORLD && {
            exportType: 'journeyWorldXlsx',
          }),
          ...([CURRENT_VIEW_EXPORT_TYPE, CURRENT_VIEW_SPLIT_EXPORT_TYPE, CURRENT_VIEW_REVIT_EXPORT_TYPE].includes(exportingType) && {
            exportType: exportingType,
          }),
        };

        if (this.isSharedLink && exportingType === ELECTRO_VALUE) {
          await ProjectsApi.projectSharedLink({
            projectId: id,
            isShared: this.isSharedLink,
            workspaceId,
          });
        }
        await this.exportScheduleProject(variables);
        this.dialogForLimitByTag = false;

        if (!skipVersion) {
          this.useExportMessage();
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
      this.resetSelections();
    },
    async useExportMessage() {
      const confirmToProceed = await this.$openConfirm({
        text: AUTO_CREATE_VERSION_ON_EXPORT,
        accept: 'Yes',
        cancel: 'No',
      });
      if (confirmToProceed) {
        this.setIssuanceMenu(true);
      }
    },
    exportProjectHandle(data) {
      const { name, templateName, fileFormat, exportType, external = true } = data;
      this.fileFormat = fileFormat;
      this.templateName = templateName;
      this.exportingType = exportType ?? name;

      if (
        this.isShowLimitProducts &&
        this.isCustomLimit({
          type: name,
        })
      ) {
        this.dialogForLimitByTag = true;
        return;
      }
      this.exportProject({
        skipVersion: external,
      });
    },
    externalPrint() {
      const exportItem = this.currentViewExport;
      const exportType = exportItem.types.find((item) => item.name === PDF && item.value == CURRENT_VIEW_EXPORT_TYPE);
      const data = {
        name: exportType.customPdfType || exportItem.name.value,
        templateName: exportItem.template,
        fileFormat: exportType.name,
        exportType: exportType.value,
        external: true,
      };
      this.exportProjectHandle(data);
    },
    isCustomLimit({ type }) {
      return this.customLimitsByView[type];
    },
    resetSelections() {
      this.listKey += 1;
      this.customLimitsByView = {
      };
    },
  },
};